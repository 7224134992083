<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ `${contact.company_name}` }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.TAGS") }}
          </dt>
          <dd class="col-sm-8">
            <tags :tags="contact.tags" @tagsUpdated="contactUpdated" />
          </dd>
        </dl>

        <dl class="row" v-if="contact.contactable.type">
          <dt class="col-sm-4">
            {{ contactableTypeName }}
          </dt>
          <dd class="col-sm-8">
            <router-link
              v-if="contact.organization"
              :to="$objectViewRoute(contact.contactable)"
            >
              {{ `${contactableName}` }}
            </router-link>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TITLE") }}</dt>
          <dd class="col-sm-8">
            {{ contact.title }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ contact.firstname }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LASTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ contact.lastname }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COMPANY_NAME") }}</dt>
          <dd class="col-sm-8">
            {{ contact.company_name }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ contact.email }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.PHONE") }}</dt>
          <dd class="col-sm-8">
            {{ contact.phone }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
          <dd class="col-sm-8">
            {{ contact.country }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
          <dd class="col-sm-8">
            {{ contact.state }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
          <dd class="col-sm-8">
            {{ contact.city }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd class="col-sm-8">
            {{ contact.zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ contact.address }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="contact.allowedLocations" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="contact.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ contact.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ contact.updated_at | moment("LLLL") }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Tags from "@/components/Tags.vue";

export default {
  name: "contact-view-global",

  props: ["contact"],

  components: { Tags },

  data() {
    return {};
  },

  computed: {
    contactableName() {
      if (this.contact.contactable.type == "suppliers") {
        return this.contact.contactable.company_name;
      } else if (this.contact.contactable.type == "customers") {
        return this.contact.contactable.customer_name;
      }
      return "N/A";
    },
    contactableTypeName() {
      if (this.contact.contactable.type == "suppliers") {
        return this.$t("COMMON.SUPPLIER");
      } else if (this.contact.contactable.type == "customers") {
        return this.$t("COMMON.CUSTOMER");
      }
      return "N/A";
    },
  },

  created() {},

  methods: {
    contactUpdated() {
      this.$emit("contactUpdated", true);
    },
  },

  mounted() {},

  watch: {
    contact(contact) {},
  },
};
</script>
